import { call, put, select } from 'redux-saga/effects'
import moment from 'moment'

import { clearNotification, setNotification } from '../../Reducers/notificationHandling'
import {
  clearCredentials,
  clearForgotPassword,
  clearRegister,
  logTime,
  setIsAuth,
  setKeepLoggedIn,
  setUser,
  setDefaultUrl,
} from '../../Reducers/user'
import { requestLogin, requestRegister, requestForgotPassword, requestResetPassword, requestLogout, requestProfile } from '../requests/user'
import { toggleLoader, setLoader } from '../../Reducers/loader'
import { reduxLoad, reduxUnload } from '../../Reducers'
import { setPermissions } from '../../Reducers/permissions'
import { setPermissionUpdatedTime } from '../../Reducers/CMS'
import { getLocalStorageItem, setCookie } from '../../../Helper/cookieHelper'
import { clearNav } from '../../Reducers/navigations'

export function* handleLogin() {
  try {
    const state = yield select()
    const keepme = state.User.credentials.keepme
    const history = state.User.credentials.history
    delete state.User.credentials.history

    yield put(setLoader(true))
    const response = yield call(requestLogin, state.User.credentials)
    const { data } = response
    if (data.status === 1) {
      yield put(setNotification({ type: 'success', message: data.message, status: data.status }))
      yield put(logTime(new Date()))
      yield put(setIsAuth(true))
      yield put(reduxLoad())
      setCookie('AUTH_TOKEN', data?.token)
      if (keepme) {
        yield put(setKeepLoggedIn())
      }
      yield put(clearNav())
      yield data.homepage && put(setDefaultUrl(data.homepage))
      if (data.homepage) {
        history.push(data.homepage)
      } else {
        const main_page = state.Navigations?.InnerSidebarNav?.find((item) => item.main_page)
        const currentURL = getLocalStorageItem('currentURL')
        if(currentURL){
          console.log('current url:::::::::', currentURL)
          history.push(currentURL)
        }else{
           if (main_page) {
             history.push('/' + main_page.route)
           } else {
             const first_page = state.Navigations?.InnerSidebarNav && state.Navigations?.InnerSidebarNav[0]
             history.push(first_page?.route)
           }
        }
       
      }
    } else {
      yield put(setNotification({ type: 'error', message: data.message, status: data.status }))
    }
  } catch (error) {
    yield put(setNotification({ type: 'error', message: error.message, status: error.status }))
  }
  yield put(clearNotification())
  yield put(clearCredentials())
  yield put(setLoader(false))
}

export function* handleRegister() {
  try {
    const state = yield select()
    const history = state.User.register.history
    delete state.User.register.history

    yield put(toggleLoader())
    const response = yield call(requestRegister, state.User.register)
    const { data } = response
    if (data.status === 1) {
      yield put(setNotification({ type: 'success', message: data.message, status: data.status }))
      history.push('/welcome/login')
    } else {
      yield put(setNotification({ type: 'error', message: data.message, errors: data.errors, status: data.status }))
    }
  } catch (error) {
    yield put(setNotification({ type: 'error', message: error.message, status: error.status }))
  }
  yield put(clearRegister())
  yield put(toggleLoader())
}

export function* handleForgotPassword() {
  try {
    const state = yield select()
    const history = state.User.forgotPassword.history
    delete state.User.forgotPassword.history

    yield put(setLoader(true))
    const response = yield call(requestForgotPassword, state.User.forgotPassword)
    const { data } = response
    if (data.status === 1) {
      yield put(setNotification({ type: 'success', message: data.message, status: data.status }))
      yield put(setLoader(false))
      history.push('/welcome/login')
    } else {
      yield put(setNotification({ type: 'error', message: data.message, errors: data.errors, status: data.status }))
    }
  } catch (error) {
    yield put(setNotification({ type: 'error', message: error.message, status: error.status }))
  }
  yield put(clearNotification())
  yield put(clearForgotPassword())
  yield put(setLoader(false))
}

export function* handleResetPassword() {
  try {
    const state = yield select()
    const history = state.User.resetPassword.history
    delete state.User.resetPassword.history

    yield put(setLoader(true))
    const response = yield call(requestResetPassword, state.User.resetPassword)
    const { data } = response
    if (data.password_reset === 1) {
      yield put(setNotification({ type: 'success', message: data.message, status: data.status }))
      yield put(setLoader(false))
      history.push('/welcome/login')
    } else {
      yield put(setNotification({ type: 'error', message: data.message, errors: data.errors, status: data.status }))
    }
  } catch (error) {
    yield put(setNotification({ type: 'error', message: error.message, status: error.status }))
  }
  yield put(setLoader(false))
  yield put(clearNotification())
  yield put(clearForgotPassword())
}

export function* profile(action) {
  let load = action?.data
  if (load === undefined) {
    load = true
  }

  try {
    if (load) {
      // yield put(toggleLoader())
    }

    const { data } = yield call(requestProfile)
    if (data.status === 1) {
      yield put(setPermissions(data.data.role.permissions))
      yield put(setPermissionUpdatedTime())
      delete data.data.role.permissions
      yield put(setUser(data.data))
    } else {
      yield put(setNotification({ type: 'error', message: data.message, status: data.status }))
    }
  } catch (error) {
    yield put(setNotification({ type: 'error', message: error.message, status: error.status }))
  }
  if (load) {
    // yield put(toggleLoader())
  }
}

export function* handleLogout() {
  try {
    yield put(toggleLoader())
    yield call(requestLogout)
    yield put(reduxUnload())
  } catch (error) {
    yield put(setNotification({ type: 'error', message: error.message, status: error.status }))
  }
  yield put(toggleLoader())
}

export function* userTimeout() {
  const state = yield select()
  if (moment().diff(moment(state.User.loginAt), 'days') > 0 && !state.User.keepme) {
    window.localStorage.clear()
  }
}
