import React, { useRef, useState } from 'react'
import useMediaQuery from '../../../CustomHook/useMediaQuery'
import { primary_color } from '../../../Helper/uiHelper'
import { cms } from '../../../helper'
import { useSelector } from 'react-redux'
import CMSWidgetModal from '../../CMSWidget/cmsWidgetModal'
import CMSNumberPoint from '../../CMSWidget/NumberPoint/cmsNumberPoint'

const PolicyBlock = ({ data, widget_uuid }) => {
  const isDesktop = useMediaQuery('(min-width:768px)')
  const editMode = useSelector((state) => state.CMS.editMode)

  const [modalPosition, setModalPosition] = useState()
  const sectionRef = useRef(null)

  const handleSectionClick = () => {
    if (!editMode) return
    if (sectionRef.current) {
      const rect = sectionRef.current.getBoundingClientRect()
      setModalPosition({
        // top: rect.top + window.scrollY - 50,
        top: rect.height + 150,

        //  left: 100, // Position below the parent element
        left: 0, // Align left edge with parent element
        width: '100%',
      })
    }
  }

  const onCloseModal = () => {
    let element = sectionRef?.current
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'start' })
    }
    setModalPosition(null)
  }
  return (
    <>
      {' '}
      <section style={{ marginTop: 10 }} ref={sectionRef} onClick={handleSectionClick} className={`m-2 ${modalPosition && 'active-cms-edit'}`}>
        <ul>
          {data?.map((item, index) => {
            return (
              <li className='d-flex' style={{ gap: 24, marginBottom: 24 }}>
                <span
                  style={{ height: isDesktop ? 28 : 24, width: 28, backgroundColor: primary_color, borderRadius: '50%', display: 'inline-block' }}
                  className='text-white d-flex align-items-center justify-content-center subtitle1'>
                  {index + 1}
                </span>
                <div
                  className='w-100 mb-0 body2 number-list'
                  dangerouslySetInnerHTML={{ __html: item?.value }}
                  style={{ color: '#000', fontWeight: 300, maxWidth: 'calc(100% - 36px)', width: '100%' }}></div>
                {/* <p className='w-100 mb-0 body2' style={{ color: '#000', fontWeight: 300, maxWidth: 'calc(100% - 36px)', width: '100%' }}>
                {item?.value}
              </p> */}
              </li>
            )
          })}
        </ul>
      </section>{' '}
      {modalPosition && (
        <CMSWidgetModal sx={{ ...modalPosition }} toggleModal={() => onCloseModal()}>
          <CMSNumberPoint data={data} toggleModal={() => onCloseModal()} widget_uuid={widget_uuid} />
        </CMSWidgetModal>
      )}
    </>
  )
}

export default PolicyBlock
