import { AvField, AvForm } from 'availity-reactstrap-validation'
import { useRef, useState } from 'react'
import Loader from 'react-loaders'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'reactstrap'
import useMediaQuery from '../../../CustomHook/useMediaQuery'
import { tidyName } from '../../../helper'
import { primary_color } from '../../../Helper/uiHelper'
import { setNotification } from '../../../Redux/Reducers/notificationHandling'
import { request } from '../../../Redux/Sagas/requests/api'
import CMSWidgetModal from '../../CMSWidget/cmsWidgetModal'
import CMSHeroBanner from '../../CMSWidget/HeroBanner/heroBanner'
import './heroBanner.scss'

const RenderFields = ({ data }) => {
  let type = data?.component_name
  if (data?.field_setting?.textarea) {
    type = 'textarea'
  }
  switch (type) {
    case 'input':
      return <AvField name={data?.field_id} placeholder={tidyName(data?.field_id)} required />
    case 'textarea':
      return <AvField type='textarea' name={data?.field_id} placeholder={tidyName(data?.field_id)} />
    default:
      return <AvField name={tidyName(data?.field_id)} />
  }
}
const HeroBanner = ({ data, searchData, setSearchData, clearSearchData, setClearSearchData, widget_uuid }) => {
  const isDesktop = useMediaQuery('(min-width:768px)')
  const dispatch = useDispatch()
  const formRef = useRef(null)
  const editMode = useSelector((state) => state.CMS.editMode)

  const [modalPosition, setModalPosition] = useState()
  const sectionRef = useRef(null)

  const handleSectionClick = () => {
    if (!editMode) return
    if (sectionRef.current) {
      const rect = sectionRef.current.getBoundingClientRect()
      setModalPosition({
        top: rect.height + 100,
        left: 200, // Align left edge with parent element
        width: '80%',
      })
    }
  }

  const [isLoading, setIsLoading] = useState(false)

  let heading = data?.heading,
    sub_heading = data?.sub_heading,
    text = data?.text,
    button_text = data?.button_text,
    form,
    image = data?.bg_image

  if (Array.isArray(data)) {
    data?.map((item) => {
      switch (item?.component_name) {
        case 'heading':
          heading = item?.value
          return
        case 'sub_heading':
          sub_heading = item?.value
          return
        case 'text':
          text = item?.value
          return
        case 'button_text':
          button_text = item?.value
          return
        case 'form':
          form = item?.forms
          return
        case 'image':
        case 'bg_image':
          image = item?.value
          return
      }
    })
  }

  const handleSubmit = (e, values) => {
    const payload = { ...values }
    setIsLoading(true)
    let url = form?.[0]?.component_call_data_connection?.[0]?.url
    request(url, 'POST', payload)
      .then((res) => {
        setIsLoading(false)
        if (res?.status == 200 || res?.status == 202) {
          dispatch(setNotification({ type: 'success', message: 'Message sent successfully!' }))
        } else {
          dispatch(setNotification({ type: 'error', message: 'Something went wrong!' }))
        }

        formRef.current.reset() // Reset the form fields
      })
      .catch(() => {
        setIsLoading(false)
        dispatch(setNotification({ type: 'error', message: 'Message sending failed!' }))
        formRef.current.reset()
      })
  }
  const onCloseModal = () => {
    let element = sectionRef?.current
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'start' })
    }
    setModalPosition(null)
  }

  return (
    <div
      className={`locker-container d-flex justify-content-center content-widget position-relative ${modalPosition && 'active-cms-edit'}`}
      ref={sectionRef}
      onClick={handleSectionClick}
      // style={{ background: '#F5FCFF' }}
      style={{ background: `url(${process.env.REACT_APP_STORAGE + image})`, backgroundSize: 'cover', border: '1px solid #00000009' }}>
      <div
        style={{
          padding: isDesktop ? '3rem 2rem' : '1rem',
          marginBlock: '6rem',
          background: form && form !== 0 ? 'transparent' : 'rgb(255 255 255 / 60%)',
          width: 'fit-content',
          maxWidth: isDesktop ? '70%' : '',
          minWidth: isDesktop ? '50rem' : '',
          border: form && form?.length !== 0 ? 'none' : '2px solid #00000009',
        }}>
        <div className='d-flex flex-column align-items-center'>
          {/* {data?.hero_search_icon ? (
            <img src={process.env.REACT_APP_PUBLIC_STORAGE + data?.hero_search_icon} width={70} height={70} className='mb-2' />
          ) : (
            <i className='fa-solid fa-school' style={{ fontSize: '3rem', color: primary_color }}></i>
          )} */}
          {/* <i className='fa-thin fa-school' style={{ fontSize: '3rem', color: primary_color }}></i> */}
          <div class='mt-3' style={isDesktop ? { width: '45rem' } : {}}>
            <div className='text-center mb-4' style={{ color: primary_color, fontWeight: '700' }} dangerouslySetInnerHTML={{ __html: heading }}></div>
            {text && <div className='text-center body_40' style={{ marginBottom: '50px' }} dangerouslySetInnerHTML={{ __html: text }}></div>}

            {sub_heading && <div className='text-muted body1 mx-4 text-center' dangerouslySetInnerHTML={{ __html: sub_heading }}></div>}
            {form?.[0]?.component_call_fields?.length > 0 && (
              <div className='px-4 hero-form' style={{ marginTop: '8rem' }}>
                <AvForm onValidSubmit={handleSubmit} ref={formRef}>
                  {form?.[0]?.component_call_fields?.map((field) => {
                    return <RenderFields data={field} />
                  })}
                  <Button
                    className='w-100 poppin d-flex align-items-center justify-content-center mt-4'
                    style={{
                      color: 'white',
                      border: `2px solid ${primary_color}`,
                      // height: isDesktop ? 45 : 45,
                      backgroundColor: primary_color,
                      borderRadius: 30,
                      paddingTop: '0.8rem',
                      paddingBottom: '0.8rem',
                    }}>
                    <span style={{ fontSize: isDesktop ? '16px' : '15px', fontWeight: 600 }}>{button_text || 'Send'}</span>
                    {isLoading && <Loader type='line-scale' color='#FFF' style={{ transform: 'scale(0.3)' }} />}
                  </Button>
                </AvForm>
              </div>
            )}
          </div>
        </div>
      </div>
      {modalPosition && (
        <CMSWidgetModal sx={{ ...modalPosition }} toggleModal={() => onCloseModal()}>
          <CMSHeroBanner data={data} toggleModal={() => onCloseModal()} widget_uuid={widget_uuid} />
        </CMSWidgetModal>
      )}
    </div>
  )
}

export default HeroBanner
