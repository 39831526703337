import { useRef, useState } from 'react'
import useMediaQuery from '../../CustomHook/useMediaQuery'
import { primary_color } from '../../Helper/uiHelper'
import CMSCardContent from '../CMSWidget/CardContent/cmsCardContent'
import CMSWidgetModal from '../CMSWidget/cmsWidgetModal'
import { useSelector } from 'react-redux'
import { Button } from 'reactstrap'
import { useHistory } from 'react-router-dom'

const Card = ({ data, widget_uuid }) => {
  const isDesktop = useMediaQuery('(min-width:768px)')

  let icon, heading, text, image

  data?.map((item) => {
    switch (item?.component_name) {
      case 'icon':
        icon = item?.value
        return
      case 'heading':
        heading = item?.value
        return
      case 'text':
        text = item?.value
        return
      case 'image':
        image = item?.value
        return
    }
  })

  console.log('card compo::::::::', image, data)

  return (
    <div
      className='my-3 d-flex justify-content-center widget-card  flex-column mx-3 position-relative'
      style={{ maxWidth: isDesktop ? '350px' : '90%' }}>
      {icon && (
        <div
          className='ml-auto mr-auto d-flex align-items-center justify-content-center text-white font-weight-bold'
          style={{
            background: primary_color,
            borderRadius: '50%',
            height: '40px',
            width: '40px',
            boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
            fontSize: '1.2rem',
            marginBottom: '4rem',
          }}>
          {icon}
        </div>
      )}

      <div className='align-self-center'>
        <img
          src={image ? process.env.REACT_APP_STORAGE + image : 'https://dev.ionicbox.com/static/media/payment-success.9d7be726204a3ade5e01.png'}
          style={{
            objectFit: 'contain',
            maxHeight: '200px',
            maxWidth: '270px',
          }}
          className={`${data?.style && data?.style}`}
        />
      </div>
      <div className='mt-5 mb-3 text-center body1' style={{ fontWeight: '800', color: '#1E1E1E' }}>
        {heading}
      </div>
      {text && (
        <div className='font-weight-light text-center subtitle2' style={{ color: primary_color }} dangerouslySetInnerHTML={{ __html: text }}></div>
      )}
    </div>
  )
}

const CardContent = ({ widget_uuid, data }) => {
  const [modalPosition, setModalPosition] = useState()
  const sectionRef = useRef(null)
  const editMode = useSelector((state) => state.CMS.editMode)
  const history = useHistory()

  let heading,
    description,
    buttonText,
    buttonIcon = 'fa-solid fa-arrow-right-long',
    action_url = 'book-my-locker-list'
  let cardData = []

  if (!Array.isArray(data)) return
  data?.map((item) => {
    if (item?.component_name === 'heading') {
      heading = item?.value
    } else if (item?.component_name === 'text') {
      description = item?.value
    } else if (item?.component_name === 'single_card') {
      cardData?.push(item)
    } else if (item?.component_name === 'button_text') {
      buttonText = item?.value
    }
  })

  const handleSectionClick = () => {
    if (!editMode) return
    if (sectionRef.current) {
      const rect = sectionRef.current.getBoundingClientRect()
      setModalPosition({
        // top: rect.top + window.scrollY - 50,
        top: rect.height + 200,

        //  left: 100, // Position below the parent element
        left: 0, // Align left edge with parent element
        width: '100%',
      })
    }
  }

  const onCloseModal = () => {
    let element = sectionRef?.current
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'start' })
    }
    setModalPosition(null)
  }

  return (
    <div className={`mt-5  ${modalPosition && 'active-cms-edit'}`} ref={sectionRef} onClick={handleSectionClick} style={{ marginBottom: '50px' }}>
      <div>
        {heading && (
          <div
            className='content-widget heading position-relative text-center'
            dangerouslySetInnerHTML={{ __html: heading }}
            style={{
              color: primary_color,
              fontWeight: '700 !important',
              marginBottom: '2rem',
            }}></div>
        )}
        {description ? <div dangerouslySetInnerHTML={{ __html: description }} className={`rich-text mb-3 content-widget text-center`}></div> : null}
      </div>
      <div
        className='d-flex flex-wrap justify-content-around widget-card-wrapper position-relative'
        style={{ marginTop: '100px', marginBottom: '100px' }}>
        {cardData?.map((item) => {
          return <Card data={item?.child_widget_data} widget_uuid={item?.uuid} />
        })}
      </div>
      {buttonText ? (
        <div className='d-flex align-items-center justify-content-center' style={{ marginBottom: '100px' }}>
          <Button
            className={` poppin d-flex align-items-center justify-content-center mb-2 text-center`}
            style={{
              color: '#fff',
              border: `2px solid ${primary_color}`,
              // maxWidth: isDesktop ? 360 : '100%',
              // height: isDesktop ? 56 : 48,
              backgroundColor: primary_color,
              borderRadius: 30,
              width: 'fit-content',
              padding: '0.8rem 6.5rem',
              marginTop: '1rem',
            }}
            onClick={() => {
              if (action_url) {
                history.push(action_url)
              }
            }}>
            <span className='body2 mr-2' style={{ fontWeight: 600 }}>
              {buttonText}
            </span>
            <i className={`${buttonIcon} ml-2 body2 font-weight-bold`}></i>
          </Button>
        </div>
      ) : null}
      <div className='mt-4 mb-4' style={{ paddingLeft: '10vw', paddingRight: '10vw' }}>
        <hr></hr>
      </div>
      {modalPosition && (
        <CMSWidgetModal sx={{ ...modalPosition }} toggleModal={() => onCloseModal()}>
          <CMSCardContent toggleModal={() => onCloseModal()} widget_uuid={widget_uuid} data={data} />
        </CMSWidgetModal>
      )}
    </div>
  )
}

export default CardContent
