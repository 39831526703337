import { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { useLocation } from 'react-router-dom'
import { Col, Row } from 'reactstrap'
import { useSelector } from 'react-redux'

import WizardTab from '../../../Components/Widget/wizardTab'
import { cms } from '../../../helper'
import { checkSideBarVisible } from '../../../Helper/publicPageHelper'
import PublicPageSidebar from './publicPageSideBar'
import PublicSection from './publicSection'
import Loader from '../../../Components/loader'
import useMediaQuery from '../../../CustomHook/useMediaQuery'
import { useFetchPage } from '../../../Queries/Page/useFetchPage'
import './Styles/publicPage.scss'
import PaymentLinkSend from '../../../Components/PageSpecificComponents/Purchase/paymentLinkSend'
import { request } from '../../../Redux/Sagas/requests/api'

const getWizardTab = (forms, activePage) => {
  const tabs = []
  let isActiveFound = false
  forms?.map((form) => {
    tabs?.push({
      name: cms(form?.cms_header_name),
      icon: form?.icon,
      isActive: activePage?.name === form?.name ? true : false,
      isVisited: activePage?.name !== form?.name && !isActiveFound,
      uuid: form?.uuid,
    })
    if (activePage?.name === form?.name) {
      isActiveFound = true
    }
  })
  return tabs
}

const PublicPage = ({ page_uuid }) => {
  const [pageData, setPageData] = useState()
  const [activePage, setActivePage] = useState()
  const [paymentLinkData, setPaymentLinkData] = useState()
  const [isLoadingPaymentLink, setIsLoadingPaymentLink] = useState(false)
  const [isPaymentRequestPreviewOpen, setIsPaymentRequestPreviewOpen] = useState(false)
  const history = useHistory()

  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const isShowOnlyContent = searchParams.get('isShowOnlyContent')
  const payment_id = searchParams.get('payment_id')
  const isVerifyPaymentLink = searchParams.get('isVerifyPaymentLink')
  const link_id = searchParams.get('link_id')
  const is_admin = searchParams.get('is_admin')

  const { data, isLoading } = useFetchPage(page_uuid)

  const isDesktop = useMediaQuery('(min-width:768px)')
  const [parentData, setParentData] = useState()
  const [searchData, setSearchData] = useState({})
  const [filterData, setFilterData] = useState({})
  const [couponCode, setCouponCode] = useState()
  const [clearSearchData, setClearSearchData] = useState([])
  const [searchResultsAvailable, setSearchResultsAvailable] = useState(true)
  const [bookingDetails, setBookingDetails] = useState()
  const [formsData, setFormData] = useState([
    {
      category_id: '',
      item_date_time_id: '',
      location_id: '',
    },
  ])
  const [unitData, setUnitData] = useState([])
  const [searchResults, setSearchResults] = useState(null)

  useEffect(() => {
    if (payment_id && !isVerifyPaymentLink) {
      setIsLoadingPaymentLink(true)
      request('check-payment-pending-status', 'POST', { payment_id }).then((res) => {
        if (res?.status === 200 || res?.status === 202) {
          setPaymentLinkData(res?.data?.data)
        }
        setIsLoadingPaymentLink(false)
      })
    }
    if (isVerifyPaymentLink) {
      setIsLoadingPaymentLink(true)
      request('check-expiry-link', 'POST', { link_id }).then((res) => {
        if (res?.status === 200 || res?.status === 202) {
          //
        } else {
          history.push('/link-expired')
        }
        setIsLoadingPaymentLink(false)
      })
    }
  }, [])

  useEffect(() => {
    const page_id = searchParams.get('page_id')
    const parent_id = searchParams.get('parent_id')
    setPageData(data)
    if (page_id && parent_id) {
      const child_page = data?.child_pages?.find((page) => page?.uuid === page_id)
      setActivePage(child_page)
      setParentData({ id: parent_id })
    } else {
      setActivePage(data)
      // setActivePage(DummyPage)
    }
  }, [page_uuid, data])

  const onGoBackToHomePage = () => {
    history.push(window.location.pathname)
    // setActivePage(pageData)
    // setParentData()

    setSearchData({})
    setFilterData({})
  }

  const wizardForm = pageData && pageData?.child_pages ? [pageData, ...pageData?.child_pages] : []
  const wizard_tab = getWizardTab(wizardForm, activePage)

  if (isLoading || isLoadingPaymentLink) {
    return <Loader />
  }

  console.log('paymentLinkData::::::::', paymentLinkData)
  if (paymentLinkData?.id) {
    return <PaymentLinkSend paymentLinkData={paymentLinkData} is_admin={is_admin}></PaymentLinkSend>
  }

  const isSidebarVisible = activePage?.sidebar_pages?.length > 0 && checkSideBarVisible(parentData, searchData, filterData, searchResultsAvailable)
  return (
    <div className='d-flex'>
      {isSidebarVisible && !isShowOnlyContent && (
        <PublicPageSidebar
          pages={activePage?.sidebar_pages}
          parentData={parentData}
          searchData={searchData}
          setSearchData={setSearchData}
          setParentData={setParentData}
          setActivePage={setActivePage}
          filterData={filterData}
          setFilterData={setFilterData}
          activePage={activePage}
          pageData={pageData}
          onGoBackToHomePage={onGoBackToHomePage}
          couponCode={couponCode}
          setCouponCode={setCouponCode}
          bookingDetails={bookingDetails}
          setBookingDetails={setBookingDetails}
          formsData={formsData}
          setFormData={setFormData}
          unitData={unitData}
          setUnitData={setUnitData}
          searchResults={searchResults}
          setSearchResults={setSearchResults}
        />
      )}
      <Row
        style={{
          flex: 1,
          height: 'fit-content',
          paddingBottom: isDesktop ? '0rem' : '0',
          paddingLeft: isDesktop ? '0' : isSidebarVisible ? '2.5rem' : '1rem',
          width: 'calc(100% - 300px)',
        }}
        className='ml-0 mr-0 w-100 main-page-container'>
        {parentData && pageData?.child_pages?.length > 0 && !isShowOnlyContent && (
          <Col md={9} className='ml-auto mr-auto' style={{ maxWidth: '1440px' }}>
            <WizardTab activePage={activePage} setActivePage={setActivePage} tabs={wizard_tab} pageData={pageData} />
          </Col>
        )}
        {activePage?.component_call_sections?.map((section, index) => {
          return (
            <Col
              key={index}
              md={parseInt(section?.col) || 6}
              className='pl-0 pr-0'
              style={isDesktop ? {} : { paddingInline: '0' }}
              data-name={section?.name}>
              <PublicSection
                section={section}
                searchData={searchData}
                setSearchData={setSearchData}
                filterData={filterData}
                setFilterData={setFilterData}
                setActivePage={setActivePage}
                activePage={activePage}
                parentData={parentData}
                setParentData={setParentData}
                pageData={pageData}
                onGoBackToHomePage={onGoBackToHomePage}
                clearSearchData={clearSearchData}
                setClearSearchData={setClearSearchData}
                searchResultsAvailable={searchResultsAvailable}
                setSearchResultsAvailable={setSearchResultsAvailable}
                couponCode={couponCode}
                setCouponCode={setCouponCode}
                bookingDetails={bookingDetails}
                setBookingDetails={setBookingDetails}
                formsData={formsData}
                setFormData={setFormData}
                unitData={unitData}
                setUnitData={setUnitData}
                searchResults={searchResults}
                setSearchResults={setSearchResults}
                setIsPaymentRequestPreviewOpen={setIsPaymentRequestPreviewOpen}
                isPaymentRequestPreviewOpen={isPaymentRequestPreviewOpen}
                setPaymentLinkData={setPaymentLinkData}
              />
            </Col>
          )
        })}
      </Row>
    </div>
  )
}

export default PublicPage
