import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button } from 'reactstrap'
import useMediaQuery from '../../../CustomHook/useMediaQuery'
import { primary_color } from '../../../Helper/uiHelper'
import CMSWidgetModal from '../../CMSWidget/cmsWidgetModal'
import CMSContent from '../../CMSWidget/Content/cmsContent'
import { useSelector } from 'react-redux'

const getWidgetData = (data) => {
  let heading,
    description,
    buttonText,
    subHeading,
    image,
    text,
    action_url = 'about-us-list',
    style
  let buttonIcon = 'fa fa-download'
  let buttonType = 'v1'

  data?.map((item) => {
    switch (item?.component_name) {
      case 'heading':
        heading = item?.value
        return
      case 'sub_heading':
        subHeading = item?.value
        return
      case 'text':
        description = item?.value
        return
      case 'button_text':
        buttonText = item?.value
        if (item?.style?.includes('outline-button')) {
          buttonType = 'v2'
        }
        return
      case 'image':
        image = item?.value
        return
      case 'action_url':
        action_url = item?.value
        return
    }
  })

  return {
    heading,
    description,
    buttonText,
    subHeading,
    image,
    text,
    action_url,
    style,
    buttonType,
    buttonIcon,
  }
}

const HomePageAboutUs = ({ widget_uuid, data }) => {
  const isDesktop = useMediaQuery('(min-width:768px)')
  const history = useHistory()
  const editMode = useSelector((state) => state.CMS.editMode)

  const [modalPosition, setModalPosition] = useState()
  const [widgetData, setWidgetData] = useState({})
  const sectionRef = useRef(null)

  useEffect(() => {
    const newData = getWidgetData(data)
    setWidgetData(newData)
  }, [data])

  //  heading={widget?.widget_data?.heading}
  //         description={widget?.widget_data?.text}
  //         buttonText={widget?.widget_data?.button_text}
  //         subHeading={widget?.widget_data?.sub_heading}
  //         image={widget?.widget_data?.image}
  //         text={widget?.widget_data?.text}
  //         action_url={widget?.widget_data?.action_url}
  //         style={widget?.widget_data?.style}
  //         buttonType={widget?.widget_data?.style?.includes('outline-button') ? 'v2' : 'v1'}
  //         buttonIcon={widget?.widget_data?.icon}

  const handleSectionClick = () => {
    if (!editMode) return
    if (sectionRef.current) {
      const rect = sectionRef.current.getBoundingClientRect()
      console.log('rect:::::::::', rect)
      setModalPosition({
        // top: rect.top + window.scrollY - 50,
        // top: 40,
        top: rect?.height + 100,

        //  left: 100, // Position below the parent element
        left: 0, // Align left edge with parent element
        width: '100%',
      })
    }
  }

  const onCloseModal = () => {
    let element = sectionRef?.current
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'start' })
    }
    setModalPosition(null)
  }

  const { heading, description, buttonText, subHeading, image, text, action_url, style, buttonType, buttonIcon } = widgetData || {}
  return (
    <div
      className={` d-flex flex-column justify-content-center position-relative ${modalPosition && 'active-cms-edit'}`}
      ref={sectionRef}
      onClick={handleSectionClick}
      style={{ marginTop: '100px' }}>
      {/* <h2 style={{ fontSize: isDesktop ? '30px' : '25px', fontWeight: '600', color: primary_color }} className='mb-2'> */}
      <div className='row'>
        <div className='col-md-7'>
          {heading && (
            <div
              className='content-widget heading position-relative'
              dangerouslySetInnerHTML={{ __html: heading }}
              style={{ color: primary_color, fontWeight: '700 !important', marginBottom: '2rem' }}></div>
          )}

          {/* {subHeading ? <div className={`mb-3 ${style && style}`}>{subHeading}</div> : null} */}
          {subHeading ? <div dangerouslySetInnerHTML={{ __html: subHeading }} className={`mb-3 content-widget ${style && style}`}></div> : null}
          {description ? (
            <div dangerouslySetInnerHTML={{ __html: description }} className={`rich-text mb-5 content-widget ${style && style}`}></div>
          ) : null}
          {buttonText ? (
            <>
              <Button
                className={` poppin d-flex align-items-center justify-content-center mb-2 ${style && style}`}
                style={{
                  color: buttonType === 'v1' ? 'white' : primary_color,
                  border: `2px solid ${primary_color}`,
                  // maxWidth: isDesktop ? 360 : '100%',
                  // height: isDesktop ? 56 : 48,
                  backgroundColor: buttonType === 'v1' ? primary_color : 'transparent',
                  borderRadius: 30,
                  width: 'fit-content',
                  padding: '0.8rem 6.5rem',
                }}
                onClick={() => {
                  if (action_url) {
                    history.push(action_url)
                  }
                }}>
                <span className='body2 mr-2' style={{ fontWeight: 600 }}>
                  {buttonText}
                </span>
                <i className={`${buttonIcon} ml-2 body2 font-weight-bold`}></i>
              </Button>
            </>
          ) : null}
        </div>
        <div className='col-md-1'></div>
        {image && (
          <div className='col-md-4 d-flex align-items-center justify-content-center'>
            <img src={process.env.REACT_APP_STORAGE + image} className={`${style && style} mb-4 mt-4 img-300`}></img>
          </div>
        )}
      </div>

      <div className='mt-4 mb-4' style={{ height: '50px' }}></div>
      <div className='mt-4 mb-4' style={{ paddingLeft: '10vw', paddingRight: '10vw' }}>
        <hr></hr>
      </div>
      {modalPosition && (
        <CMSWidgetModal sx={{ ...modalPosition }} toggleModal={() => onCloseModal()}>
          <CMSContent widget_uuid={widget_uuid} data={data} toggleModal={() => onCloseModal()} />
        </CMSWidgetModal>
      )}
    </div>
  )
}

export default HomePageAboutUs
