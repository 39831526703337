import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Col, Row } from 'reactstrap'
import Input from '../../CMSComponent/Forms/Fields/input'
import { cms, tidyName } from '../../helper'
import RoundedSearch from '../Form/Fields/roundedSearch'
import { primary_color } from '../../Helper/uiHelper'
import useMediaQuery from '../../CustomHook/useMediaQuery'
import { request } from '../../Redux/Sagas/requests/api'
import CMSWidgetModal from '../CMSWidget/cmsWidgetModal'
import { useRef, useState } from 'react'
import CMSHeroSearch from '../CMSWidget/HeroSearch/cmsHeroSearch'
import './Scss/heroSearch.scss'
import { useSelector } from 'react-redux'

const HeroSearch = ({ data, searchData, setSearchData, clearSearchData, setClearSearchData, widget_uuid }) => {
  const isDesktop = useMediaQuery('(min-width:768px)')
  const parentRef = useRef(null)
  const editMode = useSelector((state) => state.CMS.editMode)

  const [modalPosition, setModalPosition] = useState()

  const handleSectionClick = () => {
    if (!editMode) return
    if (parentRef.current) {
      const rect = parentRef.current.getBoundingClientRect()
      setModalPosition({
        top: rect.height + 100,
        // left: `${rect.left + window.scrollX + rect.width / 2 - 10}px` , // Position below the parent element
        //  left: rect.left + window.scrollX, // Align left edge with parent element
        width: '80%',
      })
    }
  }

  let image = data?.bg_image || data?.hero_search_bg

  const onCloseModal = () => {
    let element = parentRef?.current
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'start' })
    }
    setModalPosition(null)
  }

  return (
    <div
      className={`locker-container d-flex justify-content-center hero_search ${modalPosition && 'active-cms-edit'}`}
      ref={parentRef}
      onClick={handleSectionClick}
      // style={{ background: '#F5FCFF' }}
      style={{ background: `url(${process.env.REACT_APP_STORAGE + image})`, backgroundSize: 'cover', border: '1px solid #00000009' }}>
      <div className='main_content' style={{}}>
        <div className='d-flex flex-column align-items-center'>
          {data?.hero_search_icon ? (
            <img src={process.env.REACT_APP_PUBLIC_STORAGE + data?.hero_search_icon} width={70} height={70} className='mb-2' />
          ) : (
            <i className='fa-solid fa-school icon' style={{ color: primary_color }}></i>
          )}
          {/* <i className='fa-thin fa-school' style={{ fontSize: '3rem', color: primary_color }}></i> */}
          <div class='mt-3' style={isDesktop ? { width: '95%' } : {}}>
            {/* <p className='text-center' style={{ color: '#8E8E8E' }}>
              {data?.heading}
            </p> */}
            <div
              className='content-widget text-center mt-3 px-4'
              style={{ marginBottom: '3rem' }}
              dangerouslySetInnerHTML={{ __html: data?.sub_heading }}></div>

            {/* <p className='text-center body1 text-muted'>{data?.sub_heading}</p> */}
          </div>
        </div>
        <div className='d-flex align-items-center justify-content-center row ml-1 mr-1'>
          <Row style={isDesktop ? { width: '95%' } : { justifyContent: 'center' }}>
            {data?.form?.[0]?.component_call_fields?.map((field) => {
              return (
                <Col md={parseInt(field?.col) || 6} style={isDesktop ? { paddingInline: '5px' } : { marginInline: '5px' }}>
                  <RoundedSearch
                    endIcon={'fa-solid fa-xmark'}
                    startIcon={field?.icon ? field?.icon : 'fa-solid fa-magnifying-glass'}
                    placeholder={tidyName(field?.name)}
                    searchData={searchData}
                    setSearchData={setSearchData}
                    field_id={field?.field_id}
                    search_keyword_exclude={data?.search_keyword_exclude}
                    search_limit_error={data?.search_limit_error}
                    min_length={data?.min_later_count ? parseInt(data?.min_later_count) : 0}
                    clearSearchData={clearSearchData}
                    setClearSearchData={setClearSearchData}
                  />
                </Col>
              )
            })}
          </Row>
        </div>
      </div>
      {modalPosition && (
        <CMSWidgetModal sx={{ ...modalPosition }} toggleModal={() => onCloseModal()}>
          <CMSHeroSearch
            data={data}
            toggleModal={() => {
              onCloseModal()
            }}
            widget_uuid={widget_uuid}
          />
        </CMSWidgetModal>
      )}
    </div>
  )
}

export default HeroSearch
