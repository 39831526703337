import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Card } from 'reactstrap'
import { cms } from '../../helper'
import { request } from '../../Redux/Sagas/requests/api'
import { primary_color } from '../../Helper/uiHelper'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import useMediaQuery from '../../CustomHook/useMediaQuery'
import './Scss/oldBooking.scss'
import CMSWidgetModal from '../CMSWidget/cmsWidgetModal'
import CMSSearchResultx from '../CMSWidget/SearchResult/CMSSearchResult'
import CMSOldBooking from '../CMSWidget/SearchResult/CMSOldWidget'
const default_image = 'https://test-api.ionicbox.com/storage/files/1/GyexiOYukHj8.png'

const getDummyData = (data, itemsNumber) => {
  const new_data =
    data.booking_id && data.total_cost
      ? [
          {
            title: 'Reservation ID',
            value: data.booking_id,
          },
          {
            title: 'Total Cost',
            value: data.total_cost,
          },
        ]
      : []
  let units_arr = [...data?.units]
  if (itemsNumber === 'single' && data?.units && data?.units[0]) {
    units_arr = [data?.units[0]]
  }
  units_arr?.forEach((item, index) => {
    let questions =
      item?.questions?.map((element) => {
        return {
          title: element.question,
          value: element.answer,
        }
      }) || []

    let studentData = [
      ...questions,
      { title: 'Start Rent', value: item.pre_time },
      { title: 'End Rent', value: item.post_time },
      { title: 'Locker Block', value: item.location_id_label },
      { title: 'Locker Number', value: item.item_id_label },
      { title: 'Price', value: item.total_cost },
    ]

    new_data.push({ title: `Student ${index + 1}`, value: studentData, type: 'student_data' })
  })

  return [...new_data]
}

const ProvisionalBooking = ({ searchResults, data, activePage, formsData, unitData, setUnitData, setFormData, widget_uuid }) => {
  const [showAll, setShowAll] = useState(false)
  const history = useHistory()
  const location = useLocation()
  const isDesktop = useMediaQuery('(min-width:768px)')

  const searchParams = new URLSearchParams(location.search)
  const [bookingData, setBookingData] = useState(null)
  const publicUser = useSelector((state) => state.User.publicUser)
  const editMode = useSelector((state) => state.CMS.editMode)

  const widgetRef = useRef(null)
  const sectionRef = useRef(null)

  const [modalPosition, setModalPosition] = useState()
  const [modalPositionSideBar, setModalPositionSideBar] = useState()

  const handleSectionClick = () => {
    if (!editMode) return

    if (widgetRef.current) {
      const rect = widgetRef.current.getBoundingClientRect()
      setModalPosition({
        top: 200,
        // left: `${rect.left + window.scrollX + rect.width / 2 - 10}px` , // Position below the parent element
        //  left: rect.left + window.scrollX, // Align left edge with parent element
        width: '60vw',
      })
    }
  }

  const handleSectionSidebarClick = () => {
    if (!editMode) return

    if (sectionRef.current) {
      const rect = sectionRef.current.getBoundingClientRect()
      setModalPositionSideBar({
        top: 200,
        // left: `${rect.left + window.scrollX + rect.width / 2 - 10}px` , // Position below the parent element
        //  left: rect.left + window.scrollX, // Align left edge with parent element
        width: '60vw',
      })
    }
  }

  const onCloseSidebarModal = () => {
    let element = sectionRef?.current
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'start' })
    }
    setModalPositionSideBar(null)
  }

  const onCloseModal = () => {
    let element = widgetRef?.current
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'start' })
    }
    setModalPosition(null)
  }

  const _retrievePrevBooking = (data) => {
    if (data && data.units) {
      let items = JSON.parse(JSON.stringify(data.units))
      setFormData(items)
      setUnitData(items)
    }
  }
  useEffect(() => {
    if (!publicUser?.id || bookingData !== null) return
    request('retrieve-reservation-session', 'POST', { parent_id: searchParams.get('parent_id') }).then((res) => {
      setBookingData(res?.data?.data)
    })
  }, [publicUser?.id])

  if (!publicUser?.email) return
  if (!bookingData?.units || !bookingData?.units.length) return

  // Homepage :-
  if (!searchParams.get('parent_id')) {
    // if (!searchResults?.length) return

    return (
      <div className='col-md-12 ml-auto mr-auto mb-5' onClick={handleSectionClick} ref={widgetRef}>
        <div
          className='fs-4 d-flex align-items-center justify-content-between'
          style={{ margin: isDesktop ? '3rem 3rem 3rem 0.25rem' : '3rem 0.25rem' }}>
          <div dangerouslySetInnerHTML={{ __html: data?.heading || '<h5>Provisional Booking</h5>' }} className='content-widget'></div>
        </div>

        <div className=' mb-3' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div className='d-flex align-items-center'>
            <div style={{ padding: '6px', borderRadius: '50%', border: '2px solid #495057' }}>
              <img src={default_image} width='50' height='50' className='me-3' style={{}} />
            </div>
            <div className='ml-3 pt-1  body1'>
              <div className='mb-0 body1' style={isDesktop ? { fontWeight: '700' } : { fontWeight: '600', fontSize: '0.75rem' }}>
                {bookingData.domain_name}
              </div>
              <div className='d-flex mt-2' style={{ flexWrap: 'wrap' }}>
                <div className='d-flex flex-column  mb-2' style={{ marginRight: '3rem' }}>
                  <div className='font-weight-bold subtitle1'>Reservation ID</div>
                  <div className='text-muted subtitle1' style={{ fontWeight: '100' }}>
                    {bookingData?.booking_id}
                  </div>
                </div>
                <div className='d-flex flex-column  mb-2' style={{ marginRight: '3rem' }}>
                  <div className='font-weight-bold subtitle1'>Date</div>
                  <div className='text-muted subtitle1' style={{ fontWeight: '100' }}>
                    {bookingData?.start_time}-{bookingData?.end_time}
                  </div>
                </div>
                <div className='d-flex flex-column  mb-2' style={{ marginRight: '3rem' }}>
                  <div className='font-weight-bold subtitle1'>No of Locker</div>
                  <div className='text-muted subtitle1' style={{ fontWeight: '100' }}>
                    {bookingData?.units?.length || 0}
                  </div>
                </div>
              </div>
              {/* <span style={{ fontSize: isDesktop ? '0.75rem' : '0.65rem' }}>{bookingData?.address_name}</span> */}
            </div>
          </div>
          <div className='d-flex justify-content-end mt-2' style={{ marginRight: isDesktop ? '3rem' : '0', marginBlock: 'auto' }}>
            <button
              className={`btn subtitle1 old-booking-btn text-white ${isDesktop ? 'px-5' : 'px-2'}`}
              style={{ borderRadius: '30px', background: primary_color }}
              onClick={() => {
                history.push(`?parent_id=${bookingData.domain_id}&page_id=${activePage?.child_pages[0]?.uuid}&retrieve_booking=true`)
              }}>
              {data?.button_text || 'Retrieve Booking'}
            </button>
          </div>
        </div>

        {modalPosition && (
          <CMSWidgetModal sx={{ ...modalPosition }} toggleModal={() => onCloseModal()}>
            <CMSSearchResultx
              data={data}
              toggleModal={() => {
                onCloseModal()
              }}
              widget_uuid={widget_uuid}
            />
          </CMSWidgetModal>
        )}
      </div>
    )
  }
  // Sidebar :-
  const details = showAll ? getDummyData(bookingData) : getDummyData(bookingData, 'single')
  const heading = data?.heading || '<p><b>Provisional Booking</b></p>'
  return (
    <div className='mt-2' style={{ padding: '2.5rem 0', borderBottom: '1px solid lightgrey' }} onClick={handleSectionSidebarClick} ref={sectionRef}>
      <div className=' text-left  font-weight-bold mb-4' style={{ color: primary_color }}>
        <div
          className='body1 primary-color heading font-weight-bold'
          style={{ color: primary_color }}
          dangerouslySetInnerHTML={{ __html: heading }}></div>
      </div>
      {details?.map((item, index) => {
        return (
          <div className='mb-3' key={index}>
            {item.type === 'student_data' ? (
              <div>
                {
                  <div className='mb-3 font-weight-bold body1' style={{ color: primary_color }}>
                    {item?.title}
                  </div>
                }
                {item.value.map((element) => {
                  return (
                    <div className='mb-3'>
                      <div className='font-weight-bold subtitle1'>{element?.title}</div>
                      <div className='subtitle1 text-muted' style={{ fontWeight: '100' }}>
                        {element?.value || '-'}
                      </div>
                    </div>
                  )
                })}
              </div>
            ) : (
              <>
                <div className='font-weight-bold'>{item?.title}</div>
                <div>{item?.value || '-'}</div>
              </>
            )}
          </div>
        )
      })}
      {bookingData?.units.length > 2 && (
        <div className=' font-weight-bold' onClick={() => setShowAll((prev) => !prev)} style={{ cursor: 'pointer', color: primary_color }}>
          {showAll ? 'Show less' : 'Show more'}
        </div>
      )}
      <button
        onClick={() => _retrievePrevBooking(bookingData)}
        className='btn btn-primary rounded-pill mt-2 subtitle1 w-100 font-weight-bold'
        style={{ background: primary_color }}>
        {data?.button_text || 'Retrieve booking'}
      </button>
      {modalPositionSideBar && (
        <CMSWidgetModal sx={{ ...modalPositionSideBar }} toggleModal={() => onCloseSidebarModal()}>
          <CMSOldBooking
            data={data}
            toggleModal={() => {
              onCloseSidebarModal()
            }}
            widget_uuid={widget_uuid}
          />
        </CMSWidgetModal>
      )}
    </div>
  )
}

export default ProvisionalBooking
